<template>
  <v-row no-gutters>
    <v-col class="d-flex justify-center">
      <v-card v-for="label in labels" :key="label.title" rounded class="legend" elevation="0" :color="label.color">
        <v-card-text class="labelTitle text-center">
          {{ label.title }}
        </v-card-text>
        <v-card-text class="labelValue text-center">
          {{ label.value }}
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PieChartLegend",
  props: {
    labels: Array,
  }
}
</script>

<style scoped>
.legend {
  min-height: 120px;
  max-height: 120px;
  min-width: 120px;
  margin: 2px;
}

.labelTitle {
  font-size: 1.2em;
  font-family: sans-serif;
}

.labelValue {
  padding: 10px 2px 2px 2px;
  font-size: 2.4em;
  font-family: sans-serif;
}
</style>