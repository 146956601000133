<template>
  <v-row class="fill-height md4" justify="center" align="center">
    <v-col cols="12" class="pa-0">
        <v-card flat>
          <v-row no-gutters>
            <v-col class="flex-grow-0">
              <v-btn
                  icon
                  text
                  small
                  color="grey darken-2"
                  class="allignBackArrow"
                  @click="setDay(getCalenderValue, -1)"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              </v-col>
              <v-col class="flex-grow-0">
              <div class="month">
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
              </div>
              </v-col>
              <v-col class="flex-grow-0">
              <v-btn
                  icon
                  text
                  small
                  color="grey darken-2"
                  @click="setDay(getCalenderValue, 1)"
              >
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col><v-spacer></v-spacer>
          </v-row>
          <v-row no-gutters>
          <v-btn
              text
              small
              @click="setDay(new Date(), 0)"
              class="allignTodayButton"
          >
            <v-icon class="grey--text">
              mdi-calendar-start
            </v-icon>
            <span class="grey--text ml-1">Naar vandaag</span>
          </v-btn>

          </v-row>
        </v-card>
    </v-col>
    <v-col cols="12">
      <PromiseContainer :promises.sync="promises">
          <v-calendar
              :style="calendarStyle"
              v-show="true"
              ref="calendar"
              :value="getCalenderValue"
              :weekdays="weekday"
              :events="getEvents"
              :event-color="getEventColor"
              :event-height="eventHeight"
              @click:date="selectDay"
              @click:day="selectDay"
              @click:day-category="selectDay"
              @change="fetchCalendarResults()"
              color="primary"
          ></v-calendar>
            <p v-if="!getDayResults">Kalender kon niet geladen worden.</p>
      </PromiseContainer>
      <Counter v-if="getMonthResult" :month-result="getMonthResult"/>
    </v-col>
  </v-row>
</template>

<script>
import Counter from "@/components/calendar/Counter"
import format from "date-fns/format"
import PromiseContainer from "@/components/shared/PromiseContainer.vue";
import RouteNames from "@/router/RouteNames";
import {add} from "date-fns";
import routeNames from "@/router/RouteNames";

export default {
  name: "BuildBaseCalendar",
  props:{
    detailRoute: {
      type: Object,
      required: false,
      default:() => ({name: routeNames.MODIFY_USER_HOURS_DAY})
    },
    navRoute:{
      type: Object,
      required: false,
      default:() => ({name: routeNames.MODIFY_USER_HOURS})
    }
  },
  data() {
    return {
      selectedDay: null,
      daySelected: false,
      theoreticalHours: 0,
      registeredHours: 0,
      weekday: [1, 2, 3, 4, 5, 6, 0],
      events: [],
      promises:[]
    }
  },
  components: {
    PromiseContainer,
    Counter
  },
  methods: {
    selectDay(day) {
      if (this.isOtherUserView()) {
          this.$router.push({
            ...this.detailRoute,
            params: {date: day.date, userId: this.getUserId()}
          })
      } else {
        this.$router.push({name: RouteNames.DAY, params: {date: day.date}})
      }
    },
    setDay(dateValue, monthsToAdd) {
      const dateParam = add(new Date(dateValue), {months: monthsToAdd})
      if (dateParam.getMonth() !== new Date(this.getCalenderValue).getMonth()
          || dateParam.getFullYear() !== new Date(this.getCalenderValue).getFullYear()) {
        if (this.isOtherUserView()) {
          this.$router.push({
            ...this.navRoute,
            params: {date: this.getFormattedDate(new Date(dateValue), monthsToAdd), userId: this.getUserId()}
          })
        } else {
          this.$router.push({
            name: RouteNames.CALENDAR,
            params: {date: this.getFormattedDate(new Date(dateValue), monthsToAdd)}
          })
        }
      }
    },
    getEventColor(event) {
      return event.color
    },
    getUserId() {
      return this.isOtherUserView() ? this.$route.params.userId : this.$store.state.authModule.user.id
    },
    async fetchCalendarResults() {
      //TODO WimS: implement with new method
      /*promises.push(this.$store.dispatch('calendarModule/fetchMonthResult', {
        startDate: this.$refs.calendar.lastStart.date,
        endDate: this.$refs.calendar.lastEnd.date,
        userId: this.getUserId()
      }))*/
      this.promises.push(this.$store.dispatch('calendarModule/fetchDayResults', {
        startDate: this.$refs.calendar.lastStart.date,
        endDate: this.$refs.calendar.lastEnd.date,
        userId: this.getUserId()
      }))
    },
    getFormattedDate(date, monthsToAdd) {
      return format(new Date((date).setMonth(new Date(date).getMonth() + monthsToAdd)), "yyyy-MM-dd")
    },
    isOtherUserView() {
      return !!this.$route.params.userId
    }
  },
  computed: {
    eventHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 15 : 20
    },
    calendarStyle() {
      return this.$vuetify.breakpoint.smAndDown ? {height: '500px'} : {height: '600px'}
    },
    getMonthResult() {
      return this.$store.state.calendarModule.monthResult
    },
    getDayResults() {
      return this.$store.state.calendarModule.dayResults
    },
    getEvents() {
      const events = []
      if (this.getDayResults) {
        this.getDayResults.forEach(dayResult => {
          events.push({
            name: "",
            start: dayResult.day,
            end: dayResult.day,
            color: dayResult.color,
            timed: false
          })
        })
      }
      return events
    },
    getCalenderValue() {
      return this.$route.params.date ? this.$route.params.date : format(new Date(), "yyyy-MM-dd")
    }
  }
}
</script>
<style>
.v-calendar-weekly__head :nth-child(6) {
  background-color: #f2f2f2;
}

.v-calendar-weekly__week :nth-child(6) {
  background-color: #f2f2f2;
}

.v-calendar-weekly__head :nth-child(7) {
  background-color: #f2f2f2;
}

.v-calendar-weekly__week :nth-child(7) {
  background-color: #f2f2f2;
}

.month {
  width: 150px;
  text-align: center;
}

.allignBackArrow {
  margin-left: 2px;
}

.allignTodayButton {
  margin-left: -2px;
}

</style>