<script>
import {Doughnut} from "vue-chartjs"

export default {
  name: "PieChart.vue",
  extends: Doughnut,
  props: ["data", "options"],
  mounted() {
    this.addPlugin({
                     id: 'doughnut-plugin',
                     beforeDraw: function(chart) {
                       const width = chart.chart.width;
                       const height = chart.chart.height;
                       const ctx = chart.chart.ctx;

                       ctx.restore();

                       let fontSize = (height / 160).toFixed(2);
                       ctx.font = fontSize + "em sans-serif";
                       ctx.textBaseline = "middle";

                       const hours = parseInt(chart.data.datasets[0].data[0]) +
                                     parseInt(chart.data.datasets[0].data[1]) + "/" +
                                     parseInt(chart.options.title.text);
                       const hoursX = Math.round((width - ctx.measureText(hours).width) / 2);
                       const hoursY = height / 2;

                       ctx.fillText(hours, hoursX, hoursY);

                       fontSize = (height / 250).toFixed(2);
                       ctx.font = fontSize + "em sans-serif";

                       const total = "Totaal";
                       const totalX = Math.round((width - ctx.measureText(total).width) / 2);
                       const totalY = height / 2 - 50;

                       ctx.fillText(total, totalX, totalY);

                       const end = "uren";
                       const endX = Math.round((width - ctx.measureText(end).width) / 2);
                       const endY = height / 2 + 45;

                       ctx.fillText(end, endX, endY);
                       ctx.save();
                     }
                   })
    this.renderPieChart()
  },
  computed: {
    chartData: function() {
      return this.data
    }
  },
  methods: {
    renderPieChart: function() {
      this.renderChart(
          this.chartData,
          this.options)
    }
  },
  watch: {
    data: function() {
      this.renderPieChart()
    }
  }
}
</script>