<template>
  <div v-if="showData" class="mt-4">
    <PieChartLegend :labels="labels"/>
    <PieChart :data="chartData" :options="options"/>
  </div>
  <div v-else >
      Geen data om te tonen
  </div>
</template>

<script>
import PieChart from '@/components/calendar/PieChart.vue';
import PieChartLegend from "@/components/calendar/PieChartLegend";

export default {
  name: "Counter",
  components: {
    PieChartLegend,
    PieChart
  },
  props: ['monthResult'],
  data() {
    return {
      chartData: null,
      options: null,
      labels: null,
      colors: ['#99c8ba', '#f7ac7e', '#cf7272'],
    }
  },
  methods: {
    fillChart() {
      if (this.monthResult.Work.objectValue !== 0.0 || this.monthResult.Absence.objectValue !== 0.0) {
        this.labels = this.fillLabels()
        this.chartData = this.fillChartData()
        this.options = this.fillOptions()
      } else {
        this.labels = null
        this.chartData = null
        this.options = null
      }
    },
    fillLabels() {
      return [{
        title: "Gewerkt",
        value: this.monthResult.Work.objectValue,
        color: this.colors[0]
      }, {
        title: "Afwezig",
        value: this.monthResult.Absence.objectValue,
        color: this.colors[1]
      }, {
        title: "Ontbreekt",
        value: this.monthResult.Missing.objectValue,
        color: this.colors[2]
      }]
    },
    fillChartData() {
      return {
        labels: ['Werk', 'Afwezig', 'Ontbreekt'],
        datasets: [
          {
            label: '',
            data: [this.monthResult.Work.objectValue,
                   this.monthResult.Absence.objectValue,
                   this.monthResult.Missing.objectValue],
            backgroundColor: this.colors,
          }
        ]
      }
    },
    fillOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        cutoutPercentage: 65,
        title: {
          text: this.monthResult.Theoretical.objectValue
        }
      }
    }
  },
  watch: {
    monthResult: {
      immediate: true,
      handler() {
        this.fillChart()
      }
    },
  },
  computed: {
    showData() {
      return this.monthResult.Work.objectValue !== "0.0" || this.monthResult.Absence.objectValue !== "0.0" ||
             this.monthResult.Missing.objectValue !== "0.0";
    }
  }
}
</script>
